import { navigate } from "gatsby";
import React, { Component } from "react";
import SEO from "../components/Seo";
import Favicon from "../images/favicon.png";

export default class Auth extends Component {
  state = {
    password: "",
  };

  handleChangePassword = (event: any) => {
    this.setState({
      password: event.target.value,
    });
  };

  handleCheckPassword = async () => {
    if (this.state.password === "68386844") {
      await window.localStorage.setItem("dmap-auth", "Demo Authenticated");
      navigate("/");
    } else {
      window.alert("The password is incorrect");
    }
  };

  render() {
    return (
      <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <SEO title="DMAP - Auth" description="Password authenticated Page" />
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img className="mx-auto h-12 w-auto" src={Favicon} alt="DMAP Logo" />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            DMAP - Members Only
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <div className="space-y-6">
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div className="mt-1">
                  <input
                    value={this.state.password}
                    id="password"
                    name="password"
                    type="password"
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    onChange={this.handleChangePassword}
                  />
                </div>
              </div>

              <div>
                <button
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  onClick={this.handleCheckPassword}
                >
                  Sign in
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
